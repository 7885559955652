import { useEffect } from "react";
import { useRouter } from "next/router";
import { setItemCookie } from "@vfit/shared/data-access";
import { ECMP, ECMP_MAIN, getParamsInUrl } from "@vfit/consumer/data-access";
export function useEcmpCookie() {
    var asPath = useRouter().asPath;
    var checkEcmpCookie = function() {
        var paramInUrl = getParamsInUrl(ECMP);
        if (paramInUrl) {
            setItemCookie(ECMP_MAIN, paramInUrl, 21);
        }
    };
    useEffect(function() {
        checkEcmpCookie();
    }, [
        asPath
    ]);
}
