import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  color: ",
        ";\n  background-color: ",
        ";\n  padding: 8px 24px;\n  border: ",
        ";\n  border-radius: 8px;\n  height: 40.5px;\n  width: 100%;\n  font-size: 18px;\n  cursor: pointer;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Cta = styled.button.withConfig({
    componentId: "sc-13dd8747-0"
})(_templateObject(), function(props) {
    return props.variant ? "#f2f2f2" : "#e60000";
}, function(props) {
    return props.variant ? "transparent" : "#f2f2f2";
}, function(props) {
    return props.variant ? "1px solid rgba(255, 255, 255, 1)" : "1px solid rgba(128, 128, 128, 0.5)";
});
