import LoadingOrder from "../LoadingOrder/loadingOrder";
import LoadingError from "../LoadingError/loadingError";
var CHECKOUT_FLOWS = [
    {
        title: "Waiting",
        component: LoadingOrder
    },
    {
        title: "Error",
        component: LoadingError
    },
    {
        title: ""
    }, 
];
var CHECKOUT_FLOWS_ERRORS = [
    {
        title: "Error",
        component: LoadingError
    },
    {
        title: ""
    },
    {
        title: ""
    }, 
];
var retrieveProductTechnology = function() {
    var ref;
    var productTechnology = (ref = localStorage.getItem("techOffer")) === null || ref === void 0 ? void 0 : ref.replace(/["']/g, "");
    if (!productTechnology) {
        return "";
    }
    return productTechnology;
};
export { CHECKOUT_FLOWS, CHECKOUT_FLOWS_ERRORS, retrieveProductTechnology };
