import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { SoftManagerService } from "@vfit/consumer/providers";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
export function useSoftManager() {
    var client = useQueryClient();
    useEffect(function() {
        if ("object" !== "undefined") {
            window["softManager"] = _object_spread({}, SoftManagerService(client));
        }
    }, []);
    return null;
}
