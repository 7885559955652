import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  padding-bottom: ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  background: linear-gradient(to bottom, #eb1a1a, #6a0000);\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n  padding: 24px;\n  gap: 16px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  font-size: 34px;\n  color: white;\n  margin: 0;\n  font-weight: 200;\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-size: 18px;\n  color: white;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  font-size: 24px;\n  font-family: ",
        ";\n  b {\n    font-family: ",
        ";\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-size: 18px;\n  color: white;\n  text-decoration: underline;\n  text-transform: capitalize;\n  cursor: pointer;\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
function _templateObject10() {
    var data = _tagged_template_literal([
        "\n  p,\n  a {\n    margin: 0;\n    font-size: 18px;\n    color: white;\n    ",
        "\n    font-family: ",
        ";\n  }\n"
    ]);
    _templateObject10 = function _templateObject10() {
        return data;
    };
    return data;
}
function _templateObject11() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"
    ]);
    _templateObject11 = function _templateObject11() {
        return data;
    };
    return data;
}
function _templateObject12() {
    var data = _tagged_template_literal([
        "\n  font-family: ",
        ";\n  font-size: 72px;\n  line-height: 1;\n  .decimal {\n    font-size: 36px;\n    line-height: 40px;\n  }\n  .recurrence {\n    font-size: 18px;\n    font-family: ",
        ";\n  }\n"
    ]);
    _templateObject12 = function _templateObject12() {
        return data;
    };
    return data;
}
function _templateObject13() {
    var data = _tagged_template_literal([
        "\n  .old-price {\n    font-weight: 700;\n    text-decoration: line-through;\n  }\n"
    ]);
    _templateObject13 = function _templateObject13() {
        return data;
    };
    return data;
}
function _templateObject14() {
    var data = _tagged_template_literal([
        "\n  align-content: flex-end;\n  margin: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n"
    ]);
    _templateObject14 = function _templateObject14() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { fonts, pxToCssFont } from "@vfit/shared/themes";
export var WidgetWrapper = styled.div.withConfig({
    componentId: "sc-98115a2-0"
})(_templateObject(), function(param) {
    var padding = param.padding;
    return "".concat(padding, "px");
});
export var Main = styled.main.withConfig({
    componentId: "sc-98115a2-1"
})(_templateObject1());
export var Content = styled.div.withConfig({
    componentId: "sc-98115a2-2"
})(_templateObject2());
export var Title = styled.h2.withConfig({
    componentId: "sc-98115a2-3"
})(_templateObject3());
export var AddressContainer = styled.div.withConfig({
    componentId: "sc-98115a2-4"
})(_templateObject4());
export var Paragraph = styled.p.withConfig({
    componentId: "sc-98115a2-5"
})(_templateObject5());
export var AddressInformation = styled(Paragraph).withConfig({
    componentId: "sc-98115a2-6"
})(_templateObject6(), fonts.light, fonts.regular);
export var CustomAnchor = styled.a.withConfig({
    componentId: "sc-98115a2-7"
})(_templateObject7(), fonts.light);
export var PriceContainer = styled(Paragraph).withConfig({
    componentId: "sc-98115a2-8"
})(_templateObject8());
export var PriceDescription = styled(Paragraph).withConfig({
    componentId: "sc-98115a2-9"
})(_templateObject9());
export var MoreInfoContainer = styled.div.withConfig({
    componentId: "sc-98115a2-10"
})(_templateObject10(), pxToCssFont(14, 22), fonts.regular);
export var NewLoader = styled.div.withConfig({
    componentId: "sc-98115a2-11"
})(_templateObject11());
export var Price = styled(Paragraph).withConfig({
    componentId: "sc-98115a2-12"
})(_templateObject12(), fonts.exbold, fonts.light);
export var SubPrice = styled(Paragraph).withConfig({
    componentId: "sc-98115a2-13"
})(_templateObject13());
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-98115a2-14"
})(_templateObject14());
