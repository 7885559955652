import { CART_TYPE } from "@vfit/consumer/data-access";
import { EMAIL_CUSTOMER, PHONE_CUSTOMER } from "../Checkout/checkout.models";
var stepResolver = function(steps, isPortability, recognition, isEsimFullDigital) {
    var ref;
    var idStep = "";
    if (isEsimFullDigital) {
        if (!isPortability) idStep = "esimFd";
        else idStep = "portEsimFd";
    } else {
        if (!isPortability && recognition === "VR") idStep = "newVr";
        if (!isPortability && recognition === "CO") idStep = "newCo";
        if (isPortability && recognition === "VR") idStep = "portVr";
        if (isPortability && recognition === "CO") idStep = "portCo";
    }
    return steps === null || steps === void 0 ? void 0 : (ref = steps.stepsType) === null || ref === void 0 ? void 0 : ref[idStep];
};
export var organizeThankYouPage = function(dataFromCMS, owningIndividual, orderId, isPortability, recognitionMethod, customerId, isEsimFullDigital) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41, ref42, ref43, ref44, ref45, ref46, ref47, ref48, ref49, ref50, ref51, ref52, ref53, ref54, ref55, ref56, ref57, ref58, ref59, ref60, ref61, ref62, ref63, ref64, ref65, ref66, ref67, ref68, ref69, ref70, ref71, ref72;
    var nextSteps = dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref = dataFromCMS.thankyoupagemobile) === null || ref === void 0 ? void 0 : ref.nextSteps;
    var phoneNumber = localStorage.getItem(PHONE_CUSTOMER) || (owningIndividual === null || owningIndividual === void 0 ? void 0 : (ref1 = owningIndividual.contactMedium) === null || ref1 === void 0 ? void 0 : (ref2 = ref1[1]) === null || ref2 === void 0 ? void 0 : ref2.phoneNumber) || "";
    var emailAddress = localStorage.getItem(EMAIL_CUSTOMER) || (owningIndividual === null || owningIndividual === void 0 ? void 0 : (ref3 = owningIndividual.contactMedium) === null || ref3 === void 0 ? void 0 : (ref4 = ref3[0]) === null || ref4 === void 0 ? void 0 : ref4.emailAddress) || "";
    var summaryCard = {
        primaryImage: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref5 = dataFromCMS.thankyoupagemobile) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.cardOrder) === null || ref6 === void 0 ? void 0 : ref6.primaryImage) || "",
        primaryImageMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref7 = dataFromCMS.thankyoupagemobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.cardOrder) === null || ref8 === void 0 ? void 0 : ref8.primaryImageMobile) || "",
        secondaryImage: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref9 = dataFromCMS.thankyoupagemobile) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.cardOrder) === null || ref10 === void 0 ? void 0 : ref10.secondaryImage) || "",
        secondaryImageMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref11 = dataFromCMS.thankyoupagemobile) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.cardOrder) === null || ref12 === void 0 ? void 0 : ref12.secondaryImageMobile) || "",
        mainTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref13 = dataFromCMS.thankyoupagemobile) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.cardOrder) === null || ref14 === void 0 ? void 0 : ref14.title) || "",
        subtitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref15 = dataFromCMS.thankyoupagemobile) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.cardOrder) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.description) === null || ref17 === void 0 ? void 0 : ref17.replace("ORDERID", "".concat(orderId))) || "",
        smsDescription: ((ref22 = dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref18 = dataFromCMS.thankyoupagemobile) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.cardOrder) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.confirmationNumber) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.description) === null || ref21 === void 0 ? void 0 : ref21.replace("ORDERID", "".concat(orderId))) === null || ref22 === void 0 ? void 0 : ref22.replace("DOSSIERID", "".concat(orderId))) || "",
        smsTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref23 = dataFromCMS.thankyoupagemobile) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.cardOrder) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.confirmationNumber) === null || ref25 === void 0 ? void 0 : ref25.contactLabel) || "",
        numberPhone: phoneNumber,
        smsLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref26 = dataFromCMS.thankyoupagemobile) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.cardOrder) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.confirmationNumber) === null || ref28 === void 0 ? void 0 : ref28.notReceivedLabel) || "",
        smsCta: {
            label: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref29 = dataFromCMS.thankyoupagemobile) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.cardOrder) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.confirmationNumber) === null || ref31 === void 0 ? void 0 : ref31.newMessageLink) || "",
            action: function() {
                return console === null || console === void 0 ? void 0 : console.log("smsCta");
            }
        },
        emailDescription: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref32 = dataFromCMS.thankyoupagemobile) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.cardOrder) === null || ref33 === void 0 ? void 0 : (ref34 = ref33.confirmationMail) === null || ref34 === void 0 ? void 0 : ref34.description) || "",
        emailTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref35 = dataFromCMS.thankyoupagemobile) === null || ref35 === void 0 ? void 0 : (ref36 = ref35.cardOrder) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.confirmationMail) === null || ref37 === void 0 ? void 0 : ref37.contactLabel) || "",
        email: emailAddress,
        emailLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref38 = dataFromCMS.thankyoupagemobile) === null || ref38 === void 0 ? void 0 : (ref39 = ref38.cardOrder) === null || ref39 === void 0 ? void 0 : (ref40 = ref39.confirmationMail) === null || ref40 === void 0 ? void 0 : ref40.notReceivedLabel) || "",
        emailCta: {
            label: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref41 = dataFromCMS.thankyoupagemobile) === null || ref41 === void 0 ? void 0 : (ref42 = ref41.cardOrder) === null || ref42 === void 0 ? void 0 : (ref43 = ref42.confirmationMail) === null || ref43 === void 0 ? void 0 : ref43.newMessageLink) || "",
            action: function() {
                return console === null || console === void 0 ? void 0 : console.log("emailCta");
            }
        }
    };
    var filteredSteps = stepResolver(nextSteps, isPortability, recognitionMethod, isEsimFullDigital);
    var nextStepsCard = {
        title: nextSteps.title || "",
        nextSteps: (filteredSteps === null || filteredSteps === void 0 ? void 0 : (ref44 = filteredSteps.steps) === null || ref44 === void 0 ? void 0 : ref44.map(function(item) {
            var ref, ref1, ref2, ref3;
            return {
                description: ((ref3 = (ref2 = (ref1 = (ref = item.description) === null || ref === void 0 ? void 0 : ref.replace("$PHONENUMBER", '<span style="color:#E60000; font-weight: 700">'.concat(phoneNumber, "</span>"))) === null || ref1 === void 0 ? void 0 : ref1.replace("$EMAIL", '<span style="color:#E60000; font-weight: 700">'.concat(emailAddress, "</span>"))) === null || ref2 === void 0 ? void 0 : ref2.replace("ORDERID", "".concat(orderId))) === null || ref3 === void 0 ? void 0 : ref3.replace("DOSSIERID", "".concat(orderId))) || ""
            };
        })) || [],
        modalTitle: (filteredSteps === null || filteredSteps === void 0 ? void 0 : filteredSteps.modalTitle) || "",
        modalDescription: (filteredSteps === null || filteredSteps === void 0 ? void 0 : filteredSteps.modalDescription) || ""
    };
    var downloadCard = {
        buttonCard: {
            heroImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref45 = dataFromCMS.thankyoupagemobile) === null || ref45 === void 0 ? void 0 : (ref46 = ref45.cardApp) === null || ref46 === void 0 ? void 0 : ref46.primaryImage) || "",
            hermoImgMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref47 = dataFromCMS.thankyoupagemobile) === null || ref47 === void 0 ? void 0 : (ref48 = ref47.cardApp) === null || ref48 === void 0 ? void 0 : ref48.primaryImageMobile) || "",
            backHeroImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref49 = dataFromCMS.thankyoupagemobile) === null || ref49 === void 0 ? void 0 : (ref50 = ref49.cardApp) === null || ref50 === void 0 ? void 0 : ref50.secondaryImage) || "",
            backHeroImgMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref51 = dataFromCMS.thankyoupagemobile) === null || ref51 === void 0 ? void 0 : (ref52 = ref51.cardApp) === null || ref52 === void 0 ? void 0 : ref52.secondaryImageMobile) || "",
            title: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref53 = dataFromCMS.thankyoupagemobile) === null || ref53 === void 0 ? void 0 : (ref54 = ref53.cardApp) === null || ref54 === void 0 ? void 0 : ref54.title) || "",
            description: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref55 = dataFromCMS.thankyoupagemobile) === null || ref55 === void 0 ? void 0 : (ref56 = ref55.cardApp) === null || ref56 === void 0 ? void 0 : ref56.description) || "",
            ctaLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref57 = dataFromCMS.thankyoupagemobile) === null || ref57 === void 0 ? void 0 : (ref58 = ref57.cardApp) === null || ref58 === void 0 ? void 0 : ref58.buttonLabel) || ""
        },
        qrCodeCard: {
            qrCodeImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref59 = dataFromCMS.thankyoupagemobile) === null || ref59 === void 0 ? void 0 : (ref60 = ref59.cardQrCode) === null || ref60 === void 0 ? void 0 : ref60.qrCodeImg) || "",
            secondaryTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref61 = dataFromCMS.thankyoupagemobile) === null || ref61 === void 0 ? void 0 : (ref62 = ref61.cardQrCode) === null || ref62 === void 0 ? void 0 : ref62.secondaryTitle) || "",
            primaryTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref63 = dataFromCMS.thankyoupagemobile) === null || ref63 === void 0 ? void 0 : (ref64 = ref63.cardQrCode) === null || ref64 === void 0 ? void 0 : ref64.primaryTitle) || "",
            description: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref65 = dataFromCMS.thankyoupagemobile) === null || ref65 === void 0 ? void 0 : (ref66 = ref65.cardQrCode) === null || ref66 === void 0 ? void 0 : ref66.description) || ""
        }
    };
    var trackOrder = {
        label: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref67 = dataFromCMS.thankyoupagemobile) === null || ref67 === void 0 ? void 0 : (ref68 = ref67.trackOrder) === null || ref68 === void 0 ? void 0 : ref68.label) || "",
        link: ((ref72 = dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref69 = dataFromCMS.thankyoupagemobile) === null || ref69 === void 0 ? void 0 : (ref70 = ref69.trackOrder) === null || ref70 === void 0 ? void 0 : (ref71 = ref70.link) === null || ref71 === void 0 ? void 0 : ref71.replace(CART_TYPE.PLACEHOLDER_ORDER_ID, orderId || "")) === null || ref72 === void 0 ? void 0 : ref72.replace(CART_TYPE.PLACEHOLDER_CUSTOMER_ID, customerId || "")) || ""
    };
    return {
        summaryCard: summaryCard,
        nextStepsCard: nextStepsCard,
        downloadCard: downloadCard,
        trackOrder: trackOrder
    };
};
