import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { allQueryKeyArray, COOKIE_CART, deleteAllCookies, isAppMock, resetKeyListAfterSubmit } from "@vfit/consumer/data-access";
import { PAGES } from "@vfit/shared/data-access";
import { resetData } from "@vfit/shared/providers";
import { useQueryClient } from "react-query";
export function useClearStorage() {
    var ref = useRouter(), pathname = ref.pathname, asPath = ref.asPath;
    var queryClient = useQueryClient();
    var noClearPages = [
        PAGES.SHOPPING_CART,
        PAGES.CHECK_ORDER,
        PAGES.THANK_YOU
    ];
    var checkClearStorage = function() {
        var submitOrderStorage = window !== undefined && !!localStorage && localStorage.getItem("submitOrder");
        var alreadySubmitterOrder = !!submitOrderStorage && submitOrderStorage !== "undefined" ? JSON.parse(submitOrderStorage) : undefined;
        var toBeClear = true;
        noClearPages.forEach(function(nC) {
            if (asPath.includes(nC)) toBeClear = false;
        });
        if (alreadySubmitterOrder && toBeClear) {
            resetData(queryClient, _to_consumable_array(resetKeyListAfterSubmit).concat(_to_consumable_array(allQueryKeyArray)));
            deleteAllCookies();
        } else if (toBeClear) {
            if (isAppMock()) {
                resetData(queryClient, allQueryKeyArray.filter(function(a) {
                    return a !== "appSession";
                }));
            } else {
                resetData(queryClient, allQueryKeyArray);
            }
            deleteAllCookies([
                COOKIE_CART
            ]);
        }
    };
    useEffect(function() {
        checkClearStorage();
    }, [
        pathname
    ]);
}
