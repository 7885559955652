import LoadingOrder from "../LoadingOrder/loadingOrder";
import LoadingError from "../LoadingError/loadingError";
var CHECKOUT_FLOWS = [
    {
        title: "Waiting",
        component: LoadingOrder
    },
    {
        title: "Error",
        component: LoadingError
    },
    {
        title: ""
    }, 
];
var CHECKOUT_FLOWS_ERRORS = [
    {
        title: "Error",
        component: LoadingError
    },
    {
        title: ""
    },
    {
        title: ""
    }, 
];
export { CHECKOUT_FLOWS, CHECKOUT_FLOWS_ERRORS };
