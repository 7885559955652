import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 32px;\n  right: 20px;\n  left: 20px;\n\n  @media (min-width: ",
        ") {\n    bottom: 64px;\n    left: 40px;\n    right: 40px;\n  }\n\n  @media (min-width: ",
        ") {\n    bottom: 60px;\n    left: 54px;\n    right: 54px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding-top: 8px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints } from "@vfit/shared/themes";
export var ButtonContainer = styled.div.withConfig({
    componentId: "sc-e0da9558-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var FormContainer = styled.div.withConfig({
    componentId: "sc-e0da9558-1"
})(_templateObject1());
