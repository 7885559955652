import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  button {\n    margin-right: 10px;\n    margin-top: 50px;\n    cursor: pointer;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  z-index: 9;\n  width: 70px;\n  height: 70px;\n  background-color: red;\n  border-radius: 50%;\n  box-shadow: 0 6px 10px 0 #666;\n  transition: all 0.1s ease-in-out;\n  font-size: 50px;\n  color: white;\n  text-align: center;\n  line-height: 70px;\n  position: fixed;\n  right: 50px;\n  bottom: 50px;\n  cursor: pointer;\n  span {\n    position: fixed;\n    right: 63px;\n    font-size: 10px;\n  }\n  :hover {\n    box-shadow: 0 6px 14px 0 #666;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import styled from "styled-components";
export var Container = styled.div.withConfig({
    componentId: "sc-85d407c8-0"
})(_templateObject());
export var ContainerButtons = styled.div.withConfig({
    componentId: "sc-85d407c8-1"
})(_templateObject1());
export var Fab = styled.div.withConfig({
    componentId: "sc-85d407c8-2"
})(_templateObject2());
