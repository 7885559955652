import { TechnologyCategories } from "@vfit/consumer/data-access";
import { EMAIL_CUSTOMER, PHONE_CUSTOMER } from "../Checkout/checkout.models";
export var organizeThankYouPage = function(dataFromCMS, owningIndividual, specificdataForTech, orderId, isPortability) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41, ref42, ref43, ref44, ref45, ref46, ref47, ref48, ref49, ref50, ref51, ref52, ref53, ref54, ref55, ref56, ref57, ref58, ref59, ref60, ref61, ref62, ref63, ref64, ref65, ref66, ref67, ref68, ref69, ref70, ref71, ref72, ref73, ref74, ref75, ref76, ref77;
    var subtitleCMS = (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref = dataFromCMS.thankyoupage) === null || ref === void 0 ? void 0 : (ref1 = ref.cardOrder) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.description) === null || ref2 === void 0 ? void 0 : ref2.replace("ORDERID", "".concat(orderId))) || "";
    var smsDescriptionCMS = ((ref7 = dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref3 = dataFromCMS.thankyoupage) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.cardOrder) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.confirmationNumber) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.description) === null || ref6 === void 0 ? void 0 : ref6.replace("ORDERID", "".concat(orderId))) === null || ref7 === void 0 ? void 0 : ref7.replace("DOSSIERID", "".concat(orderId))) || "";
    var phoneNumber = localStorage.getItem(PHONE_CUSTOMER) || (owningIndividual === null || owningIndividual === void 0 ? void 0 : (ref8 = owningIndividual.contactMedium) === null || ref8 === void 0 ? void 0 : (ref9 = ref8[1]) === null || ref9 === void 0 ? void 0 : ref9.phoneNumber) || "";
    var emailAddress = localStorage.getItem(EMAIL_CUSTOMER) || (owningIndividual === null || owningIndividual === void 0 ? void 0 : (ref10 = owningIndividual.contactMedium) === null || ref10 === void 0 ? void 0 : (ref11 = ref10[0]) === null || ref11 === void 0 ? void 0 : ref11.emailAddress) || "";
    var summaryCard = {
        primaryImage: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref12 = dataFromCMS.thankyoupage) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.cardOrder) === null || ref13 === void 0 ? void 0 : ref13.primaryImage) || "",
        primaryImageMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref14 = dataFromCMS.thankyoupage) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.cardOrder) === null || ref15 === void 0 ? void 0 : ref15.primaryImageMobile) || "",
        secondaryImage: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref16 = dataFromCMS.thankyoupage) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.cardOrder) === null || ref17 === void 0 ? void 0 : ref17.secondaryImage) || "",
        secondaryImageMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref18 = dataFromCMS.thankyoupage) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.cardOrder) === null || ref19 === void 0 ? void 0 : ref19.secondaryImageMobile) || "",
        mainTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref20 = dataFromCMS.thankyoupage) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.cardOrder) === null || ref21 === void 0 ? void 0 : ref21.title) || "",
        subtitle: subtitleCMS,
        smsDescription: smsDescriptionCMS,
        smsTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref22 = dataFromCMS.thankyoupage) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.cardOrder) === null || ref23 === void 0 ? void 0 : (ref24 = ref23.confirmationNumber) === null || ref24 === void 0 ? void 0 : ref24.contactLabel) || "",
        numberPhone: phoneNumber,
        smsLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref25 = dataFromCMS.thankyoupage) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.cardOrder) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.confirmationNumber) === null || ref27 === void 0 ? void 0 : ref27.notReceivedLabel) || "",
        smsCta: {
            label: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref28 = dataFromCMS.thankyoupage) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.cardOrder) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.confirmationNumber) === null || ref30 === void 0 ? void 0 : ref30.newMessageLink) || "",
            action: function() {
                return console === null || console === void 0 ? void 0 : console.log("smsCta");
            }
        },
        emailDescription: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref31 = dataFromCMS.thankyoupage) === null || ref31 === void 0 ? void 0 : (ref32 = ref31.cardOrder) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.confirmationMail) === null || ref33 === void 0 ? void 0 : ref33.description) || "",
        emailTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref34 = dataFromCMS.thankyoupage) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.cardOrder) === null || ref35 === void 0 ? void 0 : (ref36 = ref35.confirmationMail) === null || ref36 === void 0 ? void 0 : ref36.contactLabel) || "",
        email: emailAddress,
        emailLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref37 = dataFromCMS.thankyoupage) === null || ref37 === void 0 ? void 0 : (ref38 = ref37.cardOrder) === null || ref38 === void 0 ? void 0 : (ref39 = ref38.confirmationMail) === null || ref39 === void 0 ? void 0 : ref39.notReceivedLabel) || "",
        emailCta: {
            label: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref40 = dataFromCMS.thankyoupage) === null || ref40 === void 0 ? void 0 : (ref41 = ref40.cardOrder) === null || ref41 === void 0 ? void 0 : (ref42 = ref41.confirmationMail) === null || ref42 === void 0 ? void 0 : ref42.newMessageLink) || "",
            action: function() {
                return console === null || console === void 0 ? void 0 : console.log("emailCta");
            }
        }
    };
    var nextStepPotability = isPortability ? specificdataForTech === null || specificdataForTech === void 0 ? void 0 : (ref43 = specificdataForTech.technology) === null || ref43 === void 0 ? void 0 : ref43.checkoutGnp : specificdataForTech === null || specificdataForTech === void 0 ? void 0 : (ref44 = specificdataForTech.technology) === null || ref44 === void 0 ? void 0 : ref44.checkoutGa;
    var nextStepsCard = {
        title: (nextStepPotability === null || nextStepPotability === void 0 ? void 0 : nextStepPotability.title) || "",
        nextSteps: (nextStepPotability === null || nextStepPotability === void 0 ? void 0 : (ref45 = nextStepPotability.nextSteps) === null || ref45 === void 0 ? void 0 : ref45.map(function(item) {
            var ref, ref1, ref2, ref3, ref4, ref5, ref6;
            return {
                description: ((ref3 = (ref2 = (ref1 = item === null || item === void 0 ? void 0 : (ref = item.description) === null || ref === void 0 ? void 0 : ref.replace("$PHONENUMBER", '<span style="color:#E60000; font-weight: 700">'.concat(phoneNumber, "</span>"))) === null || ref1 === void 0 ? void 0 : ref1.replace("$EMAIL", '<span style="color:#E60000; font-weight: 700">'.concat(emailAddress, "</span>"))) === null || ref2 === void 0 ? void 0 : ref2.replace("ORDERID", "".concat(orderId))) === null || ref3 === void 0 ? void 0 : ref3.replace("DOSSIERID", "".concat(orderId))) || "",
                moreInfo: {
                    ctaLabel: (item === null || item === void 0 ? void 0 : item.detailLinkLabel) || "",
                    title: (item === null || item === void 0 ? void 0 : (ref4 = item.modal) === null || ref4 === void 0 ? void 0 : ref4.title) || "",
                    firstContent: (item === null || item === void 0 ? void 0 : (ref5 = item.modal) === null || ref5 === void 0 ? void 0 : ref5.firstContent) || "",
                    secondContent: (item === null || item === void 0 ? void 0 : (ref6 = item.modal) === null || ref6 === void 0 ? void 0 : ref6.description) || ""
                }
            };
        })) || []
    };
    var downloadCard = {
        buttonCard: {
            heroImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref46 = dataFromCMS.thankyoupage) === null || ref46 === void 0 ? void 0 : (ref47 = ref46.cardApp) === null || ref47 === void 0 ? void 0 : ref47.primaryImage) || "",
            hermoImgMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref48 = dataFromCMS.thankyoupage) === null || ref48 === void 0 ? void 0 : (ref49 = ref48.cardApp) === null || ref49 === void 0 ? void 0 : ref49.primaryImageMobile) || "",
            backHeroImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref50 = dataFromCMS.thankyoupage) === null || ref50 === void 0 ? void 0 : (ref51 = ref50.cardApp) === null || ref51 === void 0 ? void 0 : ref51.secondaryImage) || "",
            backHeroImgMobile: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref52 = dataFromCMS.thankyoupage) === null || ref52 === void 0 ? void 0 : (ref53 = ref52.cardApp) === null || ref53 === void 0 ? void 0 : ref53.secondaryImageMobile) || "",
            title: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref54 = dataFromCMS.thankyoupage) === null || ref54 === void 0 ? void 0 : (ref55 = ref54.cardApp) === null || ref55 === void 0 ? void 0 : ref55.title) || "",
            description: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref56 = dataFromCMS.thankyoupage) === null || ref56 === void 0 ? void 0 : (ref57 = ref56.cardApp) === null || ref57 === void 0 ? void 0 : ref57.description) || "",
            ctaLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref58 = dataFromCMS.thankyoupage) === null || ref58 === void 0 ? void 0 : (ref59 = ref58.cardApp) === null || ref59 === void 0 ? void 0 : ref59.buttonLabel) || ""
        },
        qrCodeCard: {
            qrCodeImg: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref60 = dataFromCMS.thankyoupage) === null || ref60 === void 0 ? void 0 : (ref61 = ref60.cardQrCode) === null || ref61 === void 0 ? void 0 : ref61.qrCodeImg) || "",
            secondaryTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref62 = dataFromCMS.thankyoupage) === null || ref62 === void 0 ? void 0 : (ref63 = ref62.cardQrCode) === null || ref63 === void 0 ? void 0 : ref63.secondaryTitle) || "",
            primaryTitle: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref64 = dataFromCMS.thankyoupage) === null || ref64 === void 0 ? void 0 : (ref65 = ref64.cardQrCode) === null || ref65 === void 0 ? void 0 : ref65.primaryTitle) || "",
            description: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref66 = dataFromCMS.thankyoupage) === null || ref66 === void 0 ? void 0 : (ref67 = ref66.cardQrCode) === null || ref67 === void 0 ? void 0 : ref67.description) || ""
        }
    };
    var appointmentCard = {
        techAppointmentData: {
            title: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref68 = dataFromCMS.thankyoupage) === null || ref68 === void 0 ? void 0 : (ref69 = ref68.takeAppointment) === null || ref69 === void 0 ? void 0 : ref69.title) || "",
            description: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref70 = dataFromCMS.thankyoupage) === null || ref70 === void 0 ? void 0 : (ref71 = ref70.takeAppointment) === null || ref71 === void 0 ? void 0 : ref71.description) || "",
            buttonLabel: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref72 = dataFromCMS.thankyoupage) === null || ref72 === void 0 ? void 0 : (ref73 = ref72.takeAppointment) === null || ref73 === void 0 ? void 0 : ref73.buttonLabel) || ""
        },
        showTechAppointment: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref74 = dataFromCMS.thankyoupage) === null || ref74 === void 0 ? void 0 : (ref75 = ref74.takeAppointment) === null || ref75 === void 0 ? void 0 : ref75.showCard) === "true",
        url: (dataFromCMS === null || dataFromCMS === void 0 ? void 0 : (ref76 = dataFromCMS.thankyoupage) === null || ref76 === void 0 ? void 0 : (ref77 = ref76.takeAppointment) === null || ref77 === void 0 ? void 0 : ref77.buttonUrl) || ""
    };
    return {
        summaryCard: summaryCard,
        nextStepsCard: nextStepsCard,
        downloadCard: downloadCard,
        appointmentCard: appointmentCard
    };
};
export var getAppointmentCookieData = function(orderId, technology, owningIndividual, storedAddressData, buildingId, reminder, portability, postalAddress, note, ftthSubType) {
    var ref, ref1;
    var timestamp = Date.now();
    var addressData = storedAddressData && JSON.parse(storedAddressData) || "";
    var phoneNumber = (ref1 = owningIndividual === null || owningIndividual === void 0 ? void 0 : (ref = owningIndividual.contactMedium) === null || ref === void 0 ? void 0 : ref.find(function(medium) {
        var ref;
        return (medium === null || medium === void 0 ? void 0 : (ref = medium.type) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "phone" && (medium === null || medium === void 0 ? void 0 : medium.phoneNumber);
    })) === null || ref1 === void 0 ? void 0 : ref1.phoneNumber;
    return {
        timestamp: timestamp,
        orderId: orderId,
        technology: technology,
        portability: portability,
        buildingId: technology === TechnologyCategories.FTTH ? buildingId : "",
        ftthSubType: ftthSubType,
        contactPhoneNumber: phoneNumber || "",
        customerFiscalCode: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.fiscalCode) || "",
        provinceOfInstallation: addressData === null || addressData === void 0 ? void 0 : addressData.stateOrProvince,
        techAppointmentReminder: reminder,
        customer: {
            firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
            lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
            city: postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.city,
            displayStateOrProvince: postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.displayStateOrProvince,
            postalCode: postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.postalCode,
            street: postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.street,
            streetNumber: postalAddress === null || postalAddress === void 0 ? void 0 : postalAddress.streetNumber,
            note: note
        }
    };
};
