import { useEffect, useRef } from "react";
import { Path } from "@vfit/shared/models";
import { ErrorCodes, productSpecificError, useCmsConfig, CMS_CONFIG, PAYMENT_ERROR_TITLE, PAYMENT_ERROR_MESSAGE, PAYMENT_SHOW_CTC, BYPASS_CALL_API_SIA, organizeCMSErrors } from "@vfit/consumer/data-access";
import { PAGES, getHome, openPopup, API } from "@vfit/shared/data-access";
import { LoggerInstance } from "@vfit/shared/providers";
import { useCheckoutFlow } from "../../../../Checkout/hooks/UseCheckoutFlow/useCheckoutFlow";
import { useCheckout } from "../../../../iBuyFixed.context";
import { retrieveProduct } from "../../../../iBuyFixed.utils";
var Checkout = function() {
    var checkoutErrors = useCheckout().checkoutErrors;
    var queryString = window.location.search;
    var stepperRef = useRef();
    var home = getHome();
    var redirectUrl = "".concat(home);
    var product = retrieveProduct();
    var shoppingCartUrl = product === null || product === void 0 ? void 0 : product.shoppingCartUrl;
    var ref = useCheckoutFlow({}), isLoading = ref.isLoading, isSuccess = ref.isSuccess, isError = ref.isError, error = ref.error;
    var ref1 = productSpecificError(product), buttonGenericError = ref1.buttonGenericError, urlGenericErrorCallMeNow = ref1.urlGenericErrorCallMeNow;
    var errorsFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR);
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR);
    var ref2 = organizeCMSErrors(errorsFromCms), redirectToShoppingCartErrorOnPayment = ref2.redirectToShoppingCartErrorOnPayment, blockingErrors = ref2.blockingErrors;
    var isDisabledRedirect = localStorage.getItem("disableRed");
    var paymentRedirectErrors = redirectToShoppingCartErrorOnPayment.map(function(errorLocal) {
        var ref;
        return errorLocal === null || errorLocal === void 0 ? void 0 : (ref = errorLocal.error) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    });
    var blockErrors = blockingErrors.map(function(errorLocal) {
        var ref;
        return errorLocal === null || errorLocal === void 0 ? void 0 : (ref = errorLocal.error) === null || ref === void 0 ? void 0 : ref.toLowerCase();
    });
    var goToError = function() {
        var showErrorModal = function() {
            var ref;
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref = checkoutErrors.showAndTrackError) === null || ref === void 0 ? void 0 : ref.call(checkoutErrors, genericError, function() {
                if (!isDisabledRedirect) {
                    localStorage.removeItem(BYPASS_CALL_API_SIA);
                    window.open(shoppingCartUrl || redirectUrl, "_self");
                }
            }, error, undefined, undefined, function() {
                return openPopup(urlGenericErrorCallMeNow);
            }, buttonGenericError);
        };
        setTimeout(function() {
            var ref;
            if (paymentRedirectErrors.includes(error === null || error === void 0 ? void 0 : (ref = error.errorType) === null || ref === void 0 ? void 0 : ref.toLowerCase())) {
                var foundedErrors = redirectToShoppingCartErrorOnPayment === null || redirectToShoppingCartErrorOnPayment === void 0 ? void 0 : redirectToShoppingCartErrorOnPayment.filter(function(el) {
                    var ref, ref1;
                    return ((ref = el.error) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (error === null || error === void 0 ? void 0 : (ref1 = error.errorType) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
                });
                var foundedError = (error === null || error === void 0 ? void 0 : error.errorCode) ? foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                    return el.errorCode == error.errorCode;
                }) : (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                    return !el.errorCode;
                })) || (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]);
                LoggerInstance.debug("FoundedError:", foundedError, error);
                localStorage.setItem(PAYMENT_ERROR_TITLE, (foundedError === null || foundedError === void 0 ? void 0 : foundedError.title) || "");
                localStorage.setItem(PAYMENT_ERROR_MESSAGE, (foundedError === null || foundedError === void 0 ? void 0 : foundedError.message) || "");
                if ((foundedError === null || foundedError === void 0 ? void 0 : foundedError.isClickToCall) === "true") {
                    localStorage.setItem(PAYMENT_SHOW_CTC, "1");
                } else {
                    localStorage.removeItem(PAYMENT_SHOW_CTC);
                }
                if (!isDisabledRedirect) {
                    localStorage.setItem(BYPASS_CALL_API_SIA, "1");
                    setTimeout(function() {
                        window.open(shoppingCartUrl ? "".concat(shoppingCartUrl, "?").concat(ErrorCodes.ERROR_ON_PAYMENT) : redirectUrl, "_self");
                    }, 200);
                } else {
                    showErrorModal();
                }
            } else {
                localStorage.removeItem(BYPASS_CALL_API_SIA);
                showErrorModal();
            }
        }, 500);
    };
    useEffect(function() {
        if (queryString === null || queryString === void 0 ? void 0 : queryString.includes(Path.URL_FORWARD)) {
            if (!isLoading && isSuccess && !isError) {
                localStorage.removeItem(BYPASS_CALL_API_SIA);
                if (!isDisabledRedirect) {
                    window.open("".concat(redirectUrl, "/").concat(PAGES.THANK_YOU), "_self");
                }
            } else if (!isLoading && isError) {
                var ref;
                if (blockErrors.includes(error === null || error === void 0 ? void 0 : (ref = error.errorType) === null || ref === void 0 ? void 0 : ref.toLowerCase())) {
                    var ref1;
                    var foundedErrors = blockingErrors.filter(function(el) {
                        var ref, ref1;
                        return ((ref = el.error) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (error === null || error === void 0 ? void 0 : (ref1 = error.errorType) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
                    });
                    var foundedError = (error === null || error === void 0 ? void 0 : error.errorCode) ? foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                        return el.errorCode == error.errorCode;
                    }) : (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors.find(function(el) {
                        return !el.errorCode;
                    })) || (foundedErrors === null || foundedErrors === void 0 ? void 0 : foundedErrors[0]);
                    if (stepperRef.current) {
                        stepperRef.current._stepperGoNext();
                    }
                    checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref1 = checkoutErrors.showAndTrackError) === null || ref1 === void 0 ? void 0 : ref1.call(checkoutErrors, genericError, function() {
                        if (!isDisabledRedirect) {
                            localStorage.removeItem(BYPASS_CALL_API_SIA);
                            window.open(shoppingCartUrl || redirectUrl, "_self");
                        }
                    }, error, foundedError ? foundedError.title : "Ops!", foundedError ? foundedError.message : "Si \xe8 verificato un errore. Premi il tasto in basso per essere ricontattato.", function() {
                        return openPopup(urlGenericErrorCallMeNow);
                    }, buttonGenericError);
                } else {
                    goToError();
                }
            }
        }
    }, [
        isLoading,
        isError,
        isSuccess
    ]);
    return null;
};
export default Checkout;
