import { useEffect } from "react";
import { useRouter } from "next/router";
import { ISoftEvents } from "@vfit/consumer/providers";
import { checkWindow } from "@vfit/shared/data-access";
import { IN_TEST, isInABTest } from "@vfit/consumer/data-access";
export function useABTest() {
    var asPath = useRouter().asPath;
    var checkClearStorage = function() {
        if (isInABTest()) {
            localStorage.setItem(IN_TEST, "1");
        }
    };
    useEffect(function() {
        var handleEventAbTest = function(event) {
            var detail = event.detail;
            if (!checkWindow()) {
                return;
            }
            if (detail) localStorage.setItem(IN_TEST, "1");
            else localStorage.removeItem(IN_TEST);
        };
        document.addEventListener(ISoftEvents.SET_AB_TEST, handleEventAbTest);
        return function() {
            document.removeEventListener(ISoftEvents.SET_AB_TEST, handleEventAbTest);
        };
    }, []);
    useEffect(function() {
        checkClearStorage();
    }, [
        asPath
    ]);
}
